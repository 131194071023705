/**
 * Configuration interface for the ObjectPoolHandler
 * @interface PoolConfig
 */
interface PoolConfig {
    /** Maximum number of items that can be stored in the queue */
    maxQueueSize: number;
    /** Time interval (in milliseconds) between processing each item */
    processInterval: number;
}

/**
 * Generic queue-based object pool handler for managing and processing items asynchronously
 * @template T The type of items to be stored and processed in the queue
 */
export class ObjectPoolHandler<T> {
    /** Internal queue to store items */
    private queue: T[] = [];

    /** Flag to prevent concurrent processing */
    private isProcessing = false;

    /** Configuration settings for the pool */
    private config: PoolConfig;

    /**
     * Creates a new ObjectPoolHandler instance
     * @param config Configuration settings for the pool
     */
    constructor(config: PoolConfig) {
        this.config = config;
    }

    /**
     * Adds a new item to the queue. If queue is full, removes oldest item
     * @param item The item to add to the queue
     * @returns Promise that resolves when the item has been added
     */
    async addItem(item: T): Promise<void> {
        if (this.queue.length >= this.config.maxQueueSize) {
            console.warn('Queue is full, dropping oldest message');
            this.queue.shift();
        }
        this.queue.push(item);
        this.monitorQueue();
    }

    /**
     * Processes items in the queue using the provided processor function
     * @param processor Async function that processes a single item
     * @returns Promise that resolves when current processing cycle is complete
     */
    /* eslint-disable-next-line */
    async processQueue(processor: (item: T) => Promise<void>): Promise<void> {
        if (this.isProcessing || this.queue.length === 0) return;

        this.isProcessing = true;
        try {
            const item = this.queue.shift()!;
            await processor(item);
        } finally {
            this.isProcessing = false;
            if (this.queue.length > 0) {
                setTimeout(() => this.processQueue(processor), this.config.processInterval);
            }
        }
    }

    /**
     * Monitors queue size and logs warning if queue is getting too large
     * @private
     */
    private monitorQueue(): void {
        if (this.queue.length > this.config.maxQueueSize / 2) {
            console.warn(`Queue is getting large: ${this.queue.length} items`);
        }
    }
}

export default ObjectPoolHandler;